
import {Fragment} from 'vue-frag'
export default {
  components:{
    Fragment
  },
  data() {
    return {
      elements: this.$store.getters.store.eshop.elements.filter(
        (e) =>
          e.page == this.$store.getters.store.eshop.layout.navBar ||
          e.page == "navBar"
      ),
    };
  },
};
